.container__footer__auth {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 50px;
    min-height: 50px;
    width: 100%;
    text-align: center;
    background: rgb(18, 16, 16);
    background: -moz-linear-gradient(90deg, rgba(18, 16, 16, 1) 30%, rgba(43, 20, 20, 1) 50%, rgba(18, 16, 16, 1) 70%);
    background: -webkit-linear-gradient(90deg, rgba(18, 16, 16, 1) 30%, rgba(43, 20, 20, 1) 50%, rgba(18, 16, 16, 1) 70%);
    background: linear-gradient(90deg, rgba(18, 16, 16, 1) 30%, rgba(43, 20, 20, 1) 50%, rgba(18, 16, 16, 1) 70%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#121010", endColorstr="#121010", GradientType=1);
    background-image: url('../../assets/window/background.png');
    border-bottom: 1px solid #2b2824;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 20px 4px inset #0f0606b3, 0px -4px 7px 0px #0f0606b3;
    border-top: 1px solid #2b2824;
}

.container__footer__auth a {
    color: #A99C8EFF;
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 1.7;
    text-decoration: underline;
    text-decoration-color: #A99C8EFF;
    text-decoration-style: solid;
    text-decoration-thickness: 1px;
    margin-left: 8px;
    z-index: 2;
    position: relative;
}

.container__footer__auth>div:nth-child(1),
.container__footer__auth>div:nth-child(3) {
    position: absolute;
    left: 0;
    top: 0;
    height: inherit;
    width: 100%;
}

.container__footer__auth>div:nth-child(1) img:nth-child(1),
.container__footer__auth>div:nth-child(3) img:nth-child(1) {
    position: absolute;
    top: 0px;
    left: 0px;
    height: inherit;
    width: 950px;
    opacity: 0.5;
}

.container__footer__auth>div:nth-child(3) img:nth-child(1) {
    right: 0;
    left: unset;
}

.container__footer__auth>div:nth-child(1) img:nth-child(2),
.container__footer__auth>div:nth-child(3) img:nth-child(2) {
    position: absolute;
    top: 0;
    width: 195px;
    height: inherit;
    z-index: 1;
}

.container__footer__auth>div:nth-child(1) img:nth-child(2) {
    left: 0;
}

.container__footer__auth>div:nth-child(3) img:nth-child(2) {
    right: 0;
    transform: scaleX(-1);
}

@media (min-width: 950px) {

    .container__footer__auth>div:nth-child(1) img:nth-child(1),
    .container__footer__auth>div:nth-child(3) img:nth-child(1) {
        width: inherit;
        opacity: 1;
    }
}