.container_warning_message_wrapper {
  overflow: hidden;
  max-height: 0px;
  opacity: 0;
  display: flex;
}

.container_warning_message {
    position: relative;
    border: 1px solid #2b2824;
    background: #1e1918;
    background: linear-gradient(90deg, rgba(68, 22, 22, 1) 0%, rgba(28, 24, 23, 1) 100%);
    box-shadow: 0 0 15px 6px inset rgba(0, 0, 0, 0.36);
    padding: var(--padding-medium-small);
    margin-bottom: var(--margin-medium-large);

    padding: 8px;
    width: calc(100% - 16px);
    text-align: center;

}
  
.container_warning_message p {
    color: var(--warning-message-content-font-color);
    font-family: 'Cambria Math', sans-serif;
    margin: 0;

    opacity: 0;
}
  
.container_warning_message h5 {
    color: var(--warning-message-title-font-color);
    font-family: 'Cambria Math', sans-serif;
    margin: 0 0 5px 0;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.1px;

    opacity: 0;
}
  
.container_warning_message img {
    position: absolute;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
}

@keyframes fadeOutWarningMessage {
    from {
      opacity: 1;
      max-height: 80px;

    }
    to {
      opacity: 0;
      max-height: 0;
    }
  }
  
  @keyframes fadeInWarningMessage {
    from {
      opacity: 0;
      max-height: 0;
    }
    to {
      opacity: 1;
      max-height: 80px;
    }
  }

  @keyframes fadeOutWarningMessageText {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes fadeInWarningMessageText {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes delayWarningMessageText {
    from {
      opacity: 0;
    }
    to {
      opacity: 0;
    }
  }

  .container_warning_message_wrapper.animation {
    animation: fadeInWarningMessage 0.25s forwards ease-in-out, fadeOutWarningMessage 0.25s forwards ease-out 10s;
  }

.animationText {
  animation: fadeInWarningMessageText 0.25s forwards ease , fadeOutWarningMessageText 0.25s ease-out forwards 10s;
}
