.wrapper_inputfield {
    width: 100%;
}

.wrapper_inputfield > div {
    display: flex;
    justify-content: space-between;
}

.wrapper_inputfield a {
    color: #A99C8EFF;
    margin-left: auto;
    font-size: 1.6rem;
    line-height: 1.7;
    text-decoration: underline;
    text-decoration-color: #A99C8EFF; /* Customize the color of the underline */
    text-decoration-style: solid; /* Style of the underline (solid, double, dotted, dashed, wavy) */
    text-decoration-thickness: 1px; /* Thickness of the underline */
}

.wrapper_inputfield label {
    color: #A99C8EFF;
    line-height: 1.7;
    font-size: 1.6rem;
}

.inner_inputfield {
    position: relative;
    display: flex;
    flex-flow: column-reverse;
}

/* Style the img elements */
.inner_inputfield img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
}

/*@media (min-width: 320px)*/

.inner_inputfield {
    width: 100%;
}

.inner_inputfield input {
    text-align: left;
    padding: 4px 8px;
    font-size: 1.6rem;
    border-width: 1px;
    z-index: 3;
}

/* Style the ornament images */
.inner_inputfield img.ornament {
    position: absolute;
    width: 20px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

/* Position the left image */
.inner_inputfield img.ornament:nth-child(1) {
    left: -12px;
}

/* Position the right image */
.inner_inputfield img.ornament:nth-child(2) {
    right: -12px;
    transform: translateY(-50%) scaleX(-1);
}

/* Style the ornamentInside images */
.inner_inputfield img.ornamentInside {
    position: absolute;
    width: 20px;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;

}

/* Position the left ornamentInside image */
.inner_inputfield img.ornamentInside:nth-child(3) {
    left:  2.5px;
}

/* Position the right ornamentInside image */
.inner_inputfield img.ornamentInside:nth-child(4) {
    right: 2.5px;
    transform: translateY(-50%) scaleX(-1);
}
