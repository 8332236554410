#table_quest {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #table_quest td, #customers th {
    border: 1px solid #463e32;

    padding: 8px;

    color: #706a62;

    text-shadow: 2px 0px 2px rgb(0 0 0);
  }
  
  #table_quest tr:nth-child(even){background-color: #11100f;}

  #table_quest tr:nth-child(odd){    background-color: #1a1211;}

  #table_quest th {
    padding-top: 6px;
    padding-bottom: 4px;
    text-align: center;
    background-color: #181515;
    color: #efb754;
    border: 1px solid #463e32;
  }

  #button_edit {
    position: relative;
    background-image: url(http://localhost:3000/static/media/Button_RL_Foreground.d86d71e….png);
    background-image: url('../../assets/button/Button_RL_Foreground.png');
    background-size: 100% 100%;
    background-blend-mode: multiply;
    /* margin: 4px; */
    padding: 2px 8px 2px 8px;
    z-index: 2;

    background-color: var(--button-font-background-color);
    text-align: center;
    font-size: 1.5rem;
    padding: var(--button-font-padding);
    outline: none;
    border: var(--button-font-border);
    color: var(--button-font-color);
    transition: background 0.3s ease;
    cursor: pointer;
    font-family: sans-serif;
    line-height: 1.1;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 6px;
    font-size: 1.4rem;
    text-shadow: unset;
  }