.container_text_with_corner_img {
    background: #1d1b1a;
    padding: 16px;
    border: 1px solid #4e483d;
    box-shadow: 0 0 13px inset #000000b3, 0 0 5px 0 #000000ba;
    position: relative;
}

.container_text_with_corner_img span {
    text-align: center;
    color: #706a62;
    font-size: 18px;
    text-shadow: 2px 0px 2px rgb(0 0 0);
}

.container_text_with_corner_img img.corner_text_container:nth-child(1) {
    position: absolute;
    width: 55px;
    top: 0;
    left: 0;
    transform: scaleY(-1);
}

.container_text_with_corner_img img.corner_text_container:nth-child(2) {
    position: absolute;
    width: 55px;
    top: 0;
    right: 0;
    transform: scale(-1, -1);
}

.container_text_with_corner_img img.corner_text_container:nth-child(3) {
    position: absolute;
    width: 55px;
    bottom: 0;
    left: 0;
}

.container_text_with_corner_img img.corner_text_container:nth-child(4) {
    position: absolute;
    width: 55px;
    bottom: 0;
    right: 0;
    transform: scaleX(-1);
}