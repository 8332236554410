.container_header {
    height: 40px;
    min-height: 40px;
    width: 100%;
    text-align: center;
    background: rgb(18, 16, 16);
    background: -moz-linear-gradient(90deg, rgba(18, 16, 16, 1) 30%, rgba(43, 20, 20, 1) 50%, rgba(18, 16, 16, 1) 70%);
    background: -webkit-linear-gradient(90deg, rgba(18, 16, 16, 1) 30%, rgba(43, 20, 20, 1) 50%, rgba(18, 16, 16, 1) 70%);
    background: linear-gradient(90deg, rgba(18, 16, 16, 1) 30%, rgba(43, 20, 20, 1) 50%, rgba(18, 16, 16, 1) 70%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#121010", endColorstr="#121010", GradientType=1);
    background-image: url('../../assets/window/background.png');
    border-bottom: 1px solid #2b2824;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.container_header h1 {
    color: #efb754;
    font-size: 2.2rem;
    margin: 0;
    text-transform: capitalize;
}

.container_header > div:nth-child(1),
.container_header > div:nth-child(3) {
    position: absolute;
    left: 0;
    top: 0;
    height: inherit;
    width: 100%;
}

.container_header > div:nth-child(1) img:nth-child(1),
.container_header > div:nth-child(3) img:nth-child(1) {
    position: absolute;
    top: 0px;
    left: 0px;
    width: inherit;
    height: inherit;
}

.container_header > div:nth-child(1) img:nth-child(2),
.container_header > div:nth-child(3) img:nth-child(2) {
    position: absolute;
    top: 0;
    width: 195px;
    height: inherit;
    z-index: 1;
}

.container_header > div:nth-child(1) img:nth-child(2) {
    left: 0;
}

.container_header > div:nth-child(3) img:nth-child(2) {
    right: 0;
    transform: scaleX(-1);
}