.react-flow__node-selectorNode {
  font-size: 12px;
  background: #eee;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.react_flow_nodes_custom {
  background: rgb(0 0 0 / 21%) !important;
  margin-top: 8px;
  border: 1px solid #2b2824;
  box-shadow: 0 0 10px 0px #0000008f inset;
}

.react_flow_nodes_custom .react-flow__node {
  background: #1f1c1bab;
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
}

.react_flow_nodes_custom .react-flow__attribution {
  display: none;
}

.react-flow__minimap-node {
  fill: #191716;
}

.react-flow__minimap {
  background-color: #0e0d0c;
}

.react-flow__minimap-mask {
  fill: #11100f;
}

.react-flow__controls {
  box-shadow: 0 0 10px 0px #0000008f outset;

}


.react-flow__controls-button,
.react-flow__controls-fitview,
.react-flow__controls-zoomout,
.react-flow__controls-interactive {
  background-color: #311414;
  border: 1px solid #6a393d;
  padding: 4px;
  transition: background-color 0.3s ease, color 0.3s ease-in-out;
  box-shadow: 0 0 8px 7px #0000003b inset;
}

.react-flow__controls-button:hover,
.react-flow__controls-fitview:hover,
.react-flow__controls-zoomout:hover,
.react-flow__controls-interactive:hover {
  background-color: #3f1616;
  border: 1px solid #6a393d;
}

.react-flow__controls-button>svg>path,
.react-flow__controls-fitview>svg>path,
.react-flow__controls-zoomout>svg>path,
.react-flow__controls-interactive>svg>path {
  fill: #c1b197;
}

.react-flow__controls-button:hover svg path,
.react-flow__controls-fitview:hover svg path,
.react-flow__controls-zoomout:hover svg path,
.react-flow__controls-interactive:hover svg path {
  fill: #efb754;
}

.node_view_control_buttons {
  position: absolute;
  top: 0;
  left: 0;
  margin: 8px;
  z-index: 5;
}

.react-flow__minimap,s
.react-flow__controls {
  margin: 8px;
}

.quest__node__handle {
  background: #311414;
  border: 1px solid #6a393d;
  border-radius: 30%;
  min-width: 10px;
  min-height: 10px;
  width: 10px;
  height: 10px;
}

.react-flow__edge g {
  stroke: rgb(239 183 84);
  stroke-width: 2px;
}

.react-flow__edge.selected .react-flow__edge-path {
  stroke: #efb754 !important;
  stroke-width: 2;
}

.react-flow__node.selected {
  box-shadow: 0 0 13px 2px #efb754;
}