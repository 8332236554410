.container_quests_overview h1,
.container_quests_overview h2,
.container_quests_overview p,
.container_quests_overview li {
    color: #9a9081;
    text-shadow: 2px 0px 2px rgb(0 0 0);
}

.container_quests_overview h1 {
    margin-top: 0px;
    color: #9a9081;
}

.container_quests_overview h2 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.container_quests_overview p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.container_quests_overview ul {
    margin-top: 0px;
    margin-bottom: 0px;
}

.container_quests_overview {
    display :flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
}

.container_quests_overview .container_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
}