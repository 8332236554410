.nano-content li {
    list-style-type: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    cursor: pointer;
}

.nano-contentul {
    overflow: hidden; /* Hide the elements initially */
    max-height: 0; /* Set max-height to 0 so that it's not visible */
    transition: max-height 0.3s ease-out; /* Add transition for smooth animation */
}

.nano-content ul.open {
    max-height: 500px; /* Adjust this value based on your content */
}

.nano-content ul li {
    background: #1a1211;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    border-bottom: none;
    list-style-type: none;
    transform: translateY(-50px); /* Initially move the elements up */
    opacity: 0; /* Initially hide the elements */
    transition: transform 0.3s ease-out, opacity 0.3s ease-out; /* Add transition for smooth animation */
}

.nano-content ul.open li {
    transform: translateY(0); /* Move the elements down when open */
    opacity: 1; /* Show the elements */
}

.nano-content li a {
    font-size: 12px;
    padding-top: 13px;
    padding-bottom: 13px;
    color: #aeb2b7;
}

.nano-content li a {
    font-family: unset !important;
}

.nano-content span {
    font-family: unset !important;
}

.nano-content li a {
    font-family: unset !important;
    color: #aeb2b7;
    text-decoration: none;
    display: block;
    padding: 18px 0 18px 25px;
    font-size: 12px;
    outline: 0;
    -webkit-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -ms-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

.nano-content  {
    padding: unset;
}

.nano-content ul {
    padding: unset;
}

.nano-content {
    list-style-type: none;
    margin: 0;
}


.nano-content li:hover,
.nano-content .sub-menu:hover{
    background: #130d0d;
}

.nano-content li:hover span,
.nano-content .sub-menu:hover span{
    color: #efb754;
}

.nano-content ul li a:hover {
    color: #efb754;
    background: #130d0d;
}

.nano-content ul li a span {
    display: inline-block;
}

.accordion-items {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
  }
  
  .accordion-items.open {
    max-height: 500px; /* Adjust this value based on your content */
  }
  
  .accordion-items li {
    transform: translateY(-50px);
    opacity: 0;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  }
  
  .accordion-items.open li {
    transform: translateY(0);
    opacity: 1;
  }