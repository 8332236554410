@font-face {
  font-family: 'Cambria Math';
  src: local('Cambria Math'), url('./fonts/CambriaMath.ttf') format('truetype');
}

@font-face {
  font-family: 'Sell Your Soul';
  src: local('Sell Your Soul'), url('./fonts/SellYourSoul.ttf') format('truetype');
}

@font-face {
  font-family: 'Goudy Bookletter 1911';
  src: local('Goudy Bookletter 1911'), url('./fonts/GoudyBookletter1911.otf') format('opentype');
}

:root {
  --link-text-color: #a6a19b;
  /* Default link text color */

  /* Custom padding variables */
  --padding-extra-small: 0.4rem;
  /* Equivalent to 4px */
  --padding-small: 0.8rem;
  /* Equivalent to 8px */
  --padding-medium-small: 1.2rem;
  /* Equivalent to 12px */
  --padding-medium: 1.6rem;
  /* Equivalent to 16px */
  --padding-medium-large: 2.0rem;
  /* Equivalent to 20px */
  --padding-large: 2.4rem;
  /* Equivalent to 24px */

  --margin-extra-small: 0.4rem;
  /* Equivalent to 4px */
  --margin-small: 0.8rem;
  /* Equivalent to 8px */
  --margin-medium-small: 1.2rem;
  /* Equivalent to 12px */
  --margin-medium: 1.6rem;
  /* Equivalent to 16px */
  --margin-medium-large: 2.0rem;
  /* Equivalent to 20px */
  --margin-large: 2.4rem;
  /* Equivalent to 24px */

  /* Login Footer */
  --login-footer-background-color: #141011;
  --login-footer-font-size: 18px;
  --login-footer-link-text-color: #6b645d;
  --login-footer-link-hover-text-color: #ab7b27;

  /* Login/Register InputFields */
  --login-input-font-size: 2.4rem;
  --login-input-font-color: #A99C8EFF;
  --login-input-font-color-placeholder: #504b46;
  --login-input-field-selected-color: #ba8314;
  --login-input-field-focus-box-shadow: 0px 0px 8px 0px #bb8415;
  --login-input-field-border-color: #6c6257;
  --login-input-field-border-size: 2px;
  --login-input-field-padding: 1.6rem 5.3rem;
  --login-input-field-background: #1f1c1b;

  --warning-message-title-font-color: #ea6d58;
  --warning-message-content-font-color: #9f978c;

  --button-font-background-color: #ff5f57;
  --button-font-color: #ffedcf;
  --button-font-hover-color: #cd2f28;
  --button-font-border: 2px solid #352f2a;
  --button-font-size: 2.4rem;
  --button-font-padding: 0.8rem 1.6rem;

  --header-top-size-height: 56px;
}

/* Reset everything for ios */
input,
textarea,
button,
select {
    -webkit-appearance: none; /* Reset default iOS styling */
    appearance: none;
    border-radius: 0; /* Remove rounded corners */
    /* Add any other default styles you want */
}

/* Specifically target iOS webkit scrollbars to ensure consistency */
::-webkit-scrollbar {
    -webkit-appearance: none;
}

/* You might also want to reset styles for other elements iOS might style by default, like search inputs */
input[type="search"] {
    -webkit-appearance: none;
    appearance: none;
}

/* Ensure buttons and input fields don't retain iOS-specific tap highlight colors */
input:active,
input:focus,
button:active,
button:focus {
    outline: none; /* Remove iOS default outline */
    -webkit-tap-highlight-color: transparent; /* Remove tap highlight color */
}


a {
  color: var(--login-footer-link-text-color);
  font-weight: 700;
}

a:hover {
  color: var(--login-footer-link-hover-text-color);
}


html {
  font-size: 62.5%;
  /* Establish 10px as the baseline font size */
}

body {
  font-size: 1.6rem;
  /* Equivalent to 16px (1.6 * 10px) */
}

a {
  font-size: 1.8rem;
  /* Equivalent to 18px (1.8 * 10px) */
}

h1 {
  font-size: 2.4rem;
  /* Equivalent to 24px (2.4 * 10px) */
  color: #c1b197;
}

p {
  font-size: 1.4rem;
  /* Equivalent to 14px (1.4 * 10px) */
  color: #8b7e6e;
}

#root {
  position: relative;
  width: 100vw;
  height: 100vh;
}

#root::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/Background.png');
  background-size: cover;
  background-position: center;
  z-index: 0;
  /* Ensure the blurred background is behind other content */
}

span {
  display: block;
  font-family: 'Cambria Math', sans-serif;
  letter-spacing: 1px;
  line-height: 1.1;
  font-size: 1.4rem;
}

h1 {
  font-family: 'Sell Your Soul', sans-serif;
  line-height: 1.1;
  letter-spacing: 1px;
  font-size: 2.2rem;
}

h2 {
  font-family: 'Sell Your Soul', sans-serif;
  line-height: 1.1;
  letter-spacing: 1px;
  font-size: 2rem;
}

h3 {
  font-family: 'Sell Your Soul', sans-serif;
  line-height: 1.1;
  letter-spacing: 1px;
  font-size: 1.9rem;
}

h4 {
  font-family: 'Sell Your Soul', sans-serif;
  line-height: 1.1;
  letter-spacing: 1px;
  font-size: 1.8rem;
}

h5 {
  font-family: 'Sell Your Soul', sans-serif;
  line-height: 1.1;
  letter-spacing: 1px;
  font-size: 1.7rem;
  line-height: 1.1;
  letter-spacing: 1px;
}

p {
  font-family: 'Cambria Math', sans-serif;
  letter-spacing: 1px;
  line-height: 1.1;
  font-size: 1.6rem;
}

button {
  font-family: 'Cambria Math', sans-serif;
  letter-spacing: 1px;
  line-height: 1.1;
  font-size: 1.6rem;
}

select,
option,
textarea {
  background-image: url('./assets/input_field/InputField_Background.png');
  background-size: cover;
  font-family: 'Goudy Bookletter 1911', sans-serif;
  letter-spacing: 1px;
  line-height: 1.1;
  font-size: 1.6rem;
  color: #A99C8EFF;
  border: 1px solid #635a4d;
  padding: 2px 6px;
  outline: unset;

  transition: border-color 0.3s, box-shadow 0.3s;

}

select:focus {
  background: #11100f;
  border: 1px solid #635a4d;
}

select {
  background-color: #1f1e1d;
  transition: background-color 0.2s ease-in-out;
}
textarea {
  padding: 6px;
}

textarea:focus {
  outline: none;
  border-color: var(--login-input-field-selected-color);
  box-shadow: var(--login-input-field-focus-box-shadow);
}

select {
  cursor: pointer;
}

/*input {
  background-image: url('./assets//input_field/InputField_Background.png');
  font-family: 'Goudy Bookletter 1911', sans-serif;
  letter-spacing: 1px;
  line-height: 1.1;
  font-size: 18px;
  color: #A99C8EFF;
  border: 1px solid #635a4d;
  padding: 2px 6px;
  outline: unset;
}*/

input {
  background-image: url('./assets//input_field/InputField_Background.png');
  font-family: 'Goudy Bookletter 1911', sans-serif;
  letter-spacing: 1px;
  line-height: 1.1;
  font-size: 1.6rem;
  color: #A99C8EFF;
  border: 1px solid #635a4d;
  padding: 2px 6px;
  outline: unset;
}

input::placeholder {
  color: #766F67FF;
}

input {
  text-align: center;
  font-family: 'Cambria Math', sans-serif;
  letter-spacing: 1px;
  line-height: 1.1;
  background: var(--login-input-field-background);
  font-size: var(--login-input-font-size);
  color: var(--login-input-font-color);
  border: var(--login-input-field-border-size) solid var(--login-input-field-border-color);
  padding: var(--login-input-field-padding);
  transition: border-color 0.3s, box-shadow 0.3s;
}

input::placeholder {
  color: var(--login-input-font-color-placeholder);
}

input:focus {
  outline: none;
  border-color: var(--login-input-field-selected-color);
  box-shadow: var(--login-input-field-focus-box-shadow);
}

button {
  border: unset;
  font-family: 'Sell Your Soul', sans-serif;;
}

button .buttonOrnaments {
  background-color: var(--button-font-background-color);
  text-align: center;
  font-size: var(--button-font-size);
  padding: var(--button-font-padding);
  outline: none;
  border: var(--button-font-border);
  color: var(--button-font-color);
  transition: background 0.3s ease;
  cursor: pointer;
  font-family: 'Sell Your Soul', sans-serif;
  line-height: 1.1;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 0 4px #fff;
}

button:hover {
  background-color: var(--button-font-hover-color);
}


/* Style other elements within the #root */
#content {
  position: relative;
  /* Make sure it's positioned */
  z-index: 2;
  /* Set a higher z-index to make it appear above the blurred background */
  /* Add other styles as needed */
}


::-webkit-scrollbar {
  background: #151011;
  box-shadow: 10px 0px 5px 0px #1b1111 inset, -2px 0px 6px 0px #2a1313 inset;
  border: 1.5px solid black;
  width: 14px;
}

::-webkit-scrollbar-thumb {
  background: #3e2325;
  box-shadow: 3px 3px 2px 0px #251b1e inset, -1px -1px 5px 0px #080100 inset;
  border: 1.5px solid black;
}

.App {
  text-align: center;
}

.draggable {
  cursor: move;
}

::selection {
  color: #11100f;
  background: #aeb2b7;
}

.custom-checkbox {
display: flex;
align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  color: #A99C8EFF;
  user-select: none; /* Prevent text selection */
  line-height: 1.4;
    font-size: 1.6rem;
}

/* Hide the default checkbox */
.custom-checkbox input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
}

/* Create a custom checkbox (a square) */
.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #3d2224;

  background-color: #130d0d;
}

/* When the checkbox is checked, change the background and content */
.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: #311414;
}

/* Style for the checkmark/check icon */
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Display the checkmark when checked */
.custom-checkbox input[type="checkbox"]:checked + .checkmark:after {
  display: block;
}

/* Style the checkmark/checked icon */
.custom-checkbox .checkmark:after {
  left: 8px;
  top: 3px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}